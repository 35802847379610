<template>
  <div class="v-draggable">
    <div class="v-draggable__row mt-2 mb-2">
      <div class="v-draggable__col">
        <div class="v-draggable__col-title mb-1">{{ colNameLeft }}</div>
        <div class="v-draggable__block-select">
          <div
              class="v-draggable__block-item"
              v-for="column in columns"
              :key="column.id"
              @click="$emit('addColumns', column)"
          >
            {{ column.name }}
            <icon-add class="v-draggable__block-icon"/>
          </div>
        </div>
      </div>
      <div class="v-draggable__col">
        <div class="v-draggable__col-title mb-1">{{colNameRight}}</div>
        <div class="v-draggable__block-select">
          <div
              class="v-draggable__block-item"
              v-for="column in selectedColumns"
              :key="column.id"
              @click="$emit('removeColumns', column)"
          >
            {{ column.name }}
            <icon-remove class="v-draggable__block-icon"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconRemove from "@/js/components/icons/IconRemove";
import IconAdd from "@/js/components/icons/IconAdd";

export default {
  name: "VDraggable",
  components: {IconAdd, IconRemove},
  props: {
    columns: Array,
    selectedColumns: Array,
    colNameLeft: String,
    colNameRight: String
  }
}
</script>

<style lang="scss" scoped>
.v-draggable {
  width: 100%;

  &__col-title {
    font-weight: 600;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__col {
    width: 48%;
  }

  &__block-select {
    background: #FFFFFF;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    height: 224px;
    overflow-y: scroll;
  }

  &__block-icon {
    display: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__block-item {
    height: 32px;
    display: grid;
    align-content: center;
    padding: 0 8px;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: var(--blue-light);

      .v-draggable__block-icon {
        display: block;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>