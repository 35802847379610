<template>
  <div class="table-columns">
    <div class="table-columns__container p-2">
      <h2 class="table-columns__title mb-2">{{ title }}</h2>
      <div class="table-columns__subtitle mb-1">{{ subtitle }}</div>
      <VSearch
          :initialValue="search"
          placeholder="Search"
          class="xs"
          @input="search = $event"
          @clear="clearSearch"
      />
      <VDraggable
          :columns="filteredColumns"
          :selectedColumns="filteredSelectedColumns"
          @addColumns="addColumns"
          @removeColumns="removeColumns"
          :colNameLeft="colNameLeft"
          :colNameRight="colNameRight"
      />

      <div class="error-text" :class="{'error': validation}">Choose at least one offer</div>

      <div v-if="!disabledBtns" class="table-columns__btns">
        <v-button type="orange" class="btn-xl mr-2" @click="save">Save</v-button>
        <v-button
            type="orange-outline"
            class="btn-xl"
            @click="cancel">
          Cancel
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VSearch from "@/js/components/common/VSearch";
import VDraggable from "@/js/components/common/VDraggable";

export default {
  name: "TableColumns",
  components: {VDraggable, VSearch},
  props: {
    validation: Boolean,
    defaultColumns: {
      type: Array,
      default: () => []
    },
    defaultSelectedColumns: {
      type: Array,
      default: () => []
    },
    selectedColumnsIds: {
      type: Array,
      default: () => []
    },
    title: String,
    subtitle: String,
    colNameLeft: {
      type: String,
      default: 'Available for selection'
    },
    colNameRight: {
      type: String,
      default: 'Selected columns'
    },
    disabledBtns: Boolean,
    searchBy: String
  },
  data() {
    return {
      search: '',
      columnsDefault: [...this.defaultColumns.filter((col) => !this.selectedColumnsIds.includes(col.id))],
      selectedColumns: [...this.defaultSelectedColumns],
      prevColumnsDefault: [...this.defaultColumns.filter((col) => !this.selectedColumnsIds.includes(col.id))],
      prevSelectedColumns: [...this.defaultSelectedColumns],
    }
  },
  computed: {
    filteredColumns() {
      let sortedColumns = [];

      if (this.searchBy === 'columnsDefault') sortedColumns = this.onSearchBy(this.searchBy);
      else sortedColumns = this.columnsDefault;

      return sortedColumns.sort((a, b) => a.id - b.id);
    },

    filteredSelectedColumns() {
      let sortedColumns = [];

      if (this.searchBy === 'selectedColumns') sortedColumns = this.onSearchBy(this.searchBy);
      else sortedColumns = this.selectedColumns;

      return sortedColumns.sort((a, b) => a.id - b.id);
    }
  },
  methods: {
    onSearchBy(property) {
      return [...this.$data[property]].filter(col => {
        return col.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    removeColumns(column) {
      const columnIndex = this.selectedColumns.findIndex(item => item.id === column.id)
      if (columnIndex !== -1) {
        this.selectedColumns.splice(columnIndex, 1)
        this.columnsDefault.push(column)
      }
    },
    addColumns(column) {
      const columnIndex = this.columnsDefault.findIndex(item => item.id === column.id)
      if (columnIndex !== -1) {
        this.columnsDefault.splice(columnIndex, 1)
        this.selectedColumns.push(column)
      }
    },
    save() {
      const selectedColumns = this.selectedColumns.map(item => item.id)
      this.$emit('save', selectedColumns)
      this.$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', false)
    },
    cancel() {
      this.clearSearch()
      this.columnsDefault = [...this.prevColumnsDefault];
      this.selectedColumns = [...this.prevSelectedColumns];
      this.$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', false)
    },
    clearSearch() {
      this.search = ''
    }
  },
  watch: {
    '$store.state.modalTableColumns.toggleTableColumns'(isOpen) {
      if (isOpen) {
        this.prevColumnsDefault = [...this.columnsDefault];
        this.prevSelectedColumns = [...this.selectedColumns];
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.table-columns {

  &__container {
    width: 100%;
    background-color: var(--white-main);
    border-radius: 8px;
  }

  &__title,
  &__subtitle {
    font-weight: 600;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
  }

  .error-text {
    display: none;
    color: var(--red-main);
    text-align: right;
  }

  .error {
    display: block;
  }
}
</style>